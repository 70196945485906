<template>
    <div>
        <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
            <b-form-group id="input-group-1">
                <b-form-input id="name" v-model="formData.name" type="text" placeholder="Ваше имя" required />
            </b-form-group>

            <b-form-group id="input-group-2">
                <b-form-input id="email" v-model="formData.email" type="email" placeholder="Адрес э-почты" required />
            </b-form-group>

            <b-form-group id="input-group-3">
                <b-form-textarea
                    id="comments"
                    v-model="formData.comment"
                    rows="5"
                    max-rows="10"
                    placeholder="Комментарии"
                    required
                />
            </b-form-group>

            <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                sitekey="6Ld1Tb8ZAAAAAHFYCv_TbcSqFGX8Whya-lp105Ok"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
            />

            <b-button block type="submit" variant="success"><font-awesome-icon icon="paper-plane" /> Отправить</b-button>
            <b-button block type="reset" variant="danger"><font-awesome-icon icon="trash" /> Сброс</b-button>
        </b-form>
        
        <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="alertType"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
        >
            <div class="text-center">{{ msg }}</div>
        </b-alert>
    </div>
</template>

<script>
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'ContactForm',
    components: {
        VueRecaptcha
    },
    data () {
        return {
            formData: {},
            msg: null,
            alertType: null,
            dismissSecs: null,
            dismissCountDown: 0,
            successMsg: "Письмо отправлено",
            failedMsg: "Что-то не так. Пожалуйста, напишите info@windorchestra.net"
        };
    },
    methods: {
        // Set the headers for any API requests
        setHeaders() {
            axios.defaults.headers.common["Content-type"] = "application/x-www-form-urlencoded";
        },
        onSubmit() {
            this.$refs.recaptcha.execute();
        },
        onCaptchaVerified(recaptchaToken) {
            this.setHeaders();

            this.formData.recaptchaToken = recaptchaToken;
            this.$refs.recaptcha.reset();

            axios.post(
                this.$apiUrl + "/contact", this.formData)
                .then(response => {
                    if (response.data) {
                        this.onReset();
                        this.showAlert("success", this.successMsg);
                    } else {
                        this.showAlert("danger", this.failedMsg, 15);
                    }
                
                })
                .catch(error => {
                    this.showAlert("danger", this.failedMsg, 15);
                    console.error(error);
                });
        },
        onCaptchaExpired() {
            this.$refs.recaptcha.reset();
        },
        onReset() {
            this.formData = {};
            this.onCaptchaExpired();
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        showAlert(type, msg, dismiss=5) {
            this.alertType = type;
            this.msg = msg;
            this.dismissCountDown = dismiss;
        }
    }
};
</script>
